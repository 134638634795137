import React from "react";

// reactstrap components
import { Badge, Container, Row, Col } from "reactstrap";

// Core Components

function FeatureDev() {
  return (
    <>
      <div className="section features-1" id="softdev">
        <Container>
          <Row>
            <Col className="mx-auto text-center" md="8">
              <h3 className="display-3">Software Development Services</h3>
              <p className="lead">
                Bringing value to your company, people and future through digital and cognitive innovation
              </p>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <div className="info">
                <div className="icon icon-lg icon-shape icon-shape-primary shadow rounded-circle">
                  <i className="fas fa-brain"></i>
                </div>
                <h6 className="info-title text-uppercase text-primary">
                  AI and Machine Learning
                </h6>
                <p className="description opacity-8">
                We apply machine learning models to unravel massive survey datasets and uncover drivers of AI maturity (and therefore, AI performance) that would have been impossible to detect with more traditional analytical methods.
                </p>
                {/* <a
                  className="text-primary"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  More about us{" "}
                  <i className="ni ni-bold-right text-primary"></i>
                </a> */}
              </div>
            </Col>
            <Col md="4">
              <div className="info">
                <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle">
                  <i className="fas fa-cloud"></i>
                </div>
                <h6 className="info-title text-uppercase text-success">
                  Cloud Solutions
                </h6>
                <p className="description opacity-8">
                We will help you accelerate cloud data adoption and unlock its full potential.
                We offer unmatched expertise, comprehensive technology solutions, a vast industry ecosystem, and intelligent insights to fuel innovation and business growth.
                </p>
                {/* <a
                  className="text-primary"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  Learn about our products{" "}
                  <i className="ni ni-bold-right text-primary"></i>
                </a> */}
              </div>
            </Col>
            <Col md="4">
              <div className="info">
                <div className="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle">
                  <i className="ni ni-world"></i>
                </div>
                <h6 className="info-title text-uppercase text-warning">
                  Full-Stack Development
                </h6>
                <p className="description opacity-8">
                  You can hire us to build full software solutions, including web services, mobile applications, and data lakes.
                </p>
                {/* <a
                  className="text-primary"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  Check our documentation{" "}
                  <i className="ni ni-bold-right text-primary"></i>
                </a> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default FeatureDev;
