import React from "react";

// reactstrap components
// import {
//
// } from "reactstrap";

// Core Components
import MainNavbar from "components/navbars/MainNavbar";
import MainFooter from "components/footers/MainFooter.js";
import FeatureSecurity from "components/features/FeatureSecurity";
import FeatureDev from "components/features/FeatureDev";
import ContactUs from "components/contact-us/ContactUs";
import HeaderMain from "components/headers/HeaderMain";

function LandingPage() {
  // React.useEffect(() => {
  //   document.body.classList.add("landing-page");
  //   window.scrollTo(0, 0);
  //   document.body.scrollTop = 0;
  //   var href = window.location.href.substring(
  //     window.location.href.lastIndexOf("#") + 1
  //   );
  //   if (
  //     window.location.href.lastIndexOf("#") > 0 &&
  //     document.getElementById(href)
  //   ) {
  //     document.getElementById(href).scrollIntoView();
  //   }
  //   return function cleanup() {
  //     document.body.classList.remove("landing-page");
  //   };
  // });
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <MainNavbar type="transparent" />
      <div className="wrapper">
        <HeaderMain />
        <FeatureSecurity />
        <FeatureDev />
        <br></br>
        <br></br>
        <ContactUs />
        <MainFooter />
      </div>
    </>
  );
}

export default LandingPage;
