import React, { useState } from "react";

// reactstrap components
import { Container, Row, Col, Modal, Button } from "reactstrap";

// Core Components

// function riskManagementModal() {
//   return <div></>




function FeatureSecurity() {
  const [modalContent, setModalContent] = useState(undefined)
  return (
    <>
      <div className="section features-6" id="security">
        <Container>

      <Modal
        isOpen={!!modalContent}
        toggle={() => setModalContent(undefined)}
        className="modal-dialog-centered"
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Type your modal title
          </h6>
          <button
            aria-label="Close"
            className="close"
            onClick={() => setModalContent(undefined)}
            type="button"
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <p>
            Far far away, behind the word mountains, far from the countries
            Vokalia and Consonantia, there live the blind texts. Separated they
            live in Bookmarksgrove right at the coast of the Semantics, a large
            language ocean.
          </p>
          <p>
            A small river named Duden flows by their place and supplies it with
            the necessary regelialia. It is a paradisematic country, in which
            roasted parts of sentences fly into your mouth.
          </p>
        </div>
        <div className="modal-footer">
          <Button
            className="ml-auto"
            color="link"
            onClick={() => setModalContent(undefined)}
            type="button"
          >
            Close
          </Button>
        </div>
      </Modal>
          <Row className="align-items-center">
            <Col lg="6">
              <div className="info info-horizontal info-hover-primary">
                <div className="icon icon-shape icon-shape-info rounded-circle text-white">
                  <i className="ni ni-check-bold text-info"></i>
                </div>
                <div className="description pl-4">
                  <h5 className="title">Risk Management</h5>
                  <p>
                  We help organizations adopt highly effective vulnerability management practices that mitigate harmful cyber risks and reduce the impact of security incidents.
                  </p>
                  {/* <a
                    className="text-info"
                    href="#pablo"
                    onClick={() => setModalContent("f")}
                  >
                    Learn more
                  </a> */}
                </div>
              </div>
              <div className="info info-horizontal info-hover-primary">
                <div className="icon icon-shape icon-shape-info rounded-circle text-white">
                  <i className="fas fa-bullseye text-info"></i>
                </div>
                <div className="description pl-4">
                  <h5 className="title">Targeted Attack Testing</h5>
                  <p>
                  We simulate the tactics, techniques and procedures (TTPs) of real-world attackers targeting your high-risk cyber assets. Our deep knowledge of advanced persistent threat (APT) attacker behavior can help you:
                  </p>
                    <ul>
                      <li>Determine whether your critical data is at risk</li>
                      <li>Identify and mitigate complex security vulnerabilities before an attacker exploits them</li>
                      <li>Gain insight into attacker motivations and targets</li>
                      <li>Get quantitative results that help measure the risk associated with your critical assets</li>
                      <li>Identify and mitigate vulnerabilities and misconfigurations that could lead to future compromise</li>
                    </ul>
                  {/* <a
                    className="text-info"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Learn more
                  </a> */}
                </div>
              </div>
              <div className="info info-horizontal info-hover-primary">
                <div className="icon icon-shape icon-shape-info rounded-circle text-white">
                  <i className="ni ni-diamond text-info"></i>
                </div>
                <div className="description pl-4">
                  <h5 className="title">Ramsonware Defense Assessment</h5>
                  <p>
                  The Ransomware Defense Assessment evaluates your environment for vulnerabilities exploited by modern, rapidly-evolving ransomware attacks.
                  Our experts achieve this by assessing the impact a ransomware attack could have on your internal network, discover what data could be jeopardized or lost and test the ability of your security controls to detect and respond to a ransomware attack.
                  </p>
                  {/* <a
                    className="text-info"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    Learn more
                  </a> */}
                </div>
              </div>
            </Col>
            <Col className="mx-md-auto" lg="6" xs="10">
              <img
                alt="..."
                className="ml-lg-5"
                src={require("assets/img/ill/ill.png")}
                width="100%"
              ></img>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default FeatureSecurity;
